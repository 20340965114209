interface Error {
    [key: string]: string;
}

export default class Errors {
    errors: Record<string, any> = {};

    /**
     * Determine if there is any errors.
     */
    isEmpty(): boolean {
        return Object.keys(this.errors).length === 0;
    }

    /**
     * Determine if an errors exists for the given field.
     */
    has(field: string): boolean {
        return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if we have any errors.
     */
    any(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Retrieve the error message for a field.
     */
    get(field: string) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    /**
     * Add the errors using the key given.
     */
    add(key: string, errors: Array<Error>) {
        this.errors[key] = errors;
    }

    /**
     * Record the new errors.
     */
    record(errors: Record<string, string[]>): void {
        this.errors = errors;
    }

    /**
     * Clear one or all error fields.
     */
    clear(field: string | null = null): void {
        return field ? this.clearField(field) : this.clearAll();
    }

    /**
     * Clear all errors.
     */
    clearAll(): void {
        this.errors = {};
    }

    /**
     * Clear a specific error field.
     */
    clearField(field: string): void {
        if (!this.errors[field]) {
            throw new Error(`The field ${field} does not exist.`);
        }

        delete this.errors[field];
    }
}

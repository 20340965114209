import { v4 as uuidv4 } from 'uuid';
import Errors from './Errors';
import wait from '~~/utils/wait';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelEndpointResponse, CollectionEndpointResponse } from '~~/app/base/endpoints/types';

class Form<Fields> {
    isLoading = false;
    originalFields: Fields;
    fields: Fields;
    translatedFields: string[] = [];
    errors: Errors;
    internalId: string;
    dirty: boolean = false;
    onUpdateCallback: Function | null = null;
    onUpdateExtraCallbacks: Function[] = [];

    constructor(fields: Fields, onUpdateCallback: Function | null = null) {
        this.internalId = uuidv4();
        this.fields = fields;
        this.originalFields = JSON.parse(JSON.stringify(fields));
        this.errors = new Errors();
        this.onUpdateCallback = onUpdateCallback;
    }

    setOnUpdateCallback(callback: Function): void {
        this.onUpdateCallback = callback;
    }

    addOnUpdateExtraCallback(callback: Function): void {
        this.onUpdateExtraCallbacks.push(callback);
    }

    getOnUpdateCallbacks(): Function[] {
        const callbacks = this.onUpdateExtraCallbacks;
        if (this.onUpdateCallback) {
            callbacks.push(this.onUpdateCallback);
        }
        return callbacks;
    }

    setTranslatedFields(fields: string[]): void {
        this.translatedFields = fields;
    }

    /**
     * Fetch all relevant data for the form.
     */
    data(except: Array<string> = []) {
        const data: Record<string, any> = {};

        this.properties().forEach((property) => {
            if (!except || !except.includes(property)) {
                data[property] = this.translatedFields.includes(property) ? this.getWithMissingTranslations(property) : this.fields[property];
            }
        });

        return data;
    }

    dataExcept(except: Array<string> = []) {
        return this.data(except);
    }

    dataOnly(only: Array<string> = []) {
        const data: Record<string, any> = {};

        this.properties().forEach((property) => {
            if (!only || only.includes(property)) {
                data[property] = this.translatedFields.includes(property) ? this.getWithMissingTranslations(property) : this.fields[property];
            }
        });

        return data;
    }

    /**
     * Return the form's data as a FormData instance.
     */
    asFormData(except: Array<string> = []): FormData {
        const data = this.data();
        const formData = new FormData();

        for (const key in data) {
            if (!except || !except.includes(key)) {
                formData.append(key, data[key]);
            }
        }

        return formData;
    }

    /**
     * Fetch all fields (data keys) of the form.
     */
    fieldsKeys(): Array<string> {
        const fields = [];

        for (const property in this.originalFields) {
            fields.push(property);
        }

        return fields;
    }

    /**
     * Alias for the fieldsKeys() method.
     */
    properties(): Array<string> {
        return this.fieldsKeys();
    }

    /**
     * Fill the original properties of the form from the object or array given.
     */
    fill(data: Fields): void {
        this.properties().forEach((property) => {
            if (typeof data[property] !== 'undefined') {
                this.set(property, data[property]);
            }
        });
    }

    /**
     * Fill the original properties of the form from the object or array given.
     */
    fillSilently(data: Fields): void {
        this.properties().forEach((property) => {
            if (typeof data[property] !== 'undefined') {
                this.setSilently(property, data[property]);
            }
        });
    }

    /**
     * Fill with a model.
     */
    fillWithModel(model: typeof BaseModel): void {
        // console.log("fillWithModel", model, this.fieldsKeys());
        this.properties().forEach((property) => {
            //   console.log("fillWithModel property", property, model[property]);
            if (typeof model[property] !== 'undefined') {
                this.setWithoutDirtiness(property, model[property]);
            }
        });
    }

    getOriginalFields(): Fields {
        return JSON.parse(JSON.stringify(this.originalFields));
    }

    /**
     * Reset the form fields.
     */
    reset(): void {
        this.fields = this.getOriginalFields();
        this.errors.clear();
        this.stop();
    }

    resetField(field: string): void {
        console.info('resetField', field, this.getOriginalFields(), this.getOriginalFields()[field as keyof Fields]);
        this.set(field, this.getOriginalFields()[field as keyof Fields]);
        this.errors.clear(field);
    }

    /**
     * Loading until the given promise is resolved.
     */
    async loadUntil<TReturnValue>(promise: Promise<TReturnValue>, minimumDuration = 0, recordErrors = true, displayErrorToast = true): Promise<TReturnValue> {
        this.load();
        const response = await Promise.all([promise, wait(minimumDuration)]).then((values) => values[0]);

        if (recordErrors && response && typeof response === 'object' && typeof response.validationErrors !== 'undefined') {
            this.onValidationErrors(response.validationErrors, displayErrorToast);
        }

        this.stop();
        return response;
    }

    onValidationErrors(errors: Record<string, string[]>, displayErrorToast = true): void {
        this.errors.record(errors);
        if (displayErrorToast) {
            useToasteoError('toasts.validation_error');
        }
    }

    /**
     * Start the form loading.
     */
    load(): void {
        this.isLoading = true;
    }

    /**
     * Stop the form loading.
     */
    stop(): void {
        this.isLoading = false;
    }

    /**
     * Get the value of a field.
     */
    get(field: string, defaultValue: string | null = ''): any {
        if (!field.includes('.')) {
            return this.fields[field as keyof Fields] || this.fields[field as keyof Fields] == 0 ? this.fields[field as keyof Fields] : defaultValue;
        }

        const parts = field.split('.');
        let value = this.fields;
        for (let i = 0; i < parts.length; i++) {
            value = value[parts[i] as keyof Fields];
        }
        return value || defaultValue;
    }

    /**
     * Get the value of a field with missing translations.
     */
    getWithMissingTranslations(field: string): TranslatedField {
        const value = this.get(field);

        if (typeof value === 'object') {
            for (const lang of useAvailableLocales()) {
                if (!value[lang]) {
                    value[lang] = '';
                }
            }
        }
        return value;
    }

    /**
     * Set the value of a field.
     * Trigger the onUpdateCallback if the value has changed.
     */
    set(field: string, value: any): void {
        this.setWithoutDirtiness(field, value);
        this.markAsDirty();
    }

    setWithoutDirtiness(field: string, value: any): void {
        const changed = this.get(field) !== value;

        this.setSilently(field, value);

        if (!changed) {
            return;
        }

        this.getOnUpdateCallbacks().forEach((callback) => {
            callback(field, value);
        });
    }

    /**
     * Set the value of a field.
     */
    setSilently(field: string, value: any): void {
        if (!field.includes('.')) {
            this.fields[field as keyof Fields] = value;
            return;
        }

        const parts = field.split('.');
        let nestedField = '';
        let nestedObject = this.fields;
        for (let i = 0; i < parts.length - 1; i++) {
            nestedField = parts[i];
            nestedObject = nestedObject[nestedField as keyof Fields];
        }
        nestedObject[parts[parts.length - 1] as keyof Fields] = value;
    }

    containsEmptyFields(): boolean {
        for (const property in this.fields) {
            if (!this.fields[property as keyof Fields]) {
                return true;
            }
        }
        return false;
    }

    containsEmptyFieldsExcept(except: Array<string>): boolean {
        for (const property in this.fields) {
            if (!this.fields[property as keyof Fields] && !except.includes(property)) {
                return true;
            }
        }
        return false;
    }

    markAsDirty(): void {
        this.dirty = true;
    }

    unmarkAsDirty(): void {
        this.dirty = false;
    }

    isDirty(): boolean {
        return this.dirty;
    }
}

export default Form;
